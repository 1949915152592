import preload from "./preload";
import bouton from "./bouton";
import loadCSS from "fg-loadcss";

const datepicker = lang => {
  loadCSS.loadCSS("/css/slchxmag.css");

  $.getScripts({
    urls: [
      "/js/lib/litepicker.js",
      "/js/lib/dayjs.min.js",
      "/js/lib/mobilefriendly.js",
      "/js/lib/zoom.js"
    ],
    cache: true, // Default
    async: false, // Default
    success: function (response) {
      initdates(lang);
    }
  });
};




const initdates = (lang) => {

  const $skilocation = $(".skilocation");
  const datevelo = $skilocation.attr("data-caloneday");
  const url = $skilocation.attr("data-url");
  const boutonstyle = $skilocation.find(".louer").attr("data-bouton");
  let dayend, startday, pickerd, pickerf;

  function pad(d) {
    return (d < 10) ? '0' + d.toString() : d.toString();
  }

  $.get(
    url,
    data => {
      const start = data.dates.debut.split('-');
      const end = data.dates.fin.split('-');
      const dayget = dayjs(start[2] + '-' + start[1] + '-' + start[0]);
      dayend = dayjs(end[2] + '-' + end[1] + '-' + end[0]);
      const daynow = dayjs();
      startday = dayget.unix() < daynow.unix() ? daynow.add(data.fdski ? Number(data.fdski) : 0, 'day') : dayget;

      const txtselect = lang ? "Select your first day of rental" : "Sélectionnez votre premier jour de location";
      const txtselectend = lang ? "Then select your last day of rental" : "Sélectionnez ensuite votre dernier jour de location";
      const textselectmag = lang ? "Select the store where you want to pick up your equipment" : "Sélectionnez le magasin dans lequel vous souhaitez retirer votre matériel";
      const magselect = lang ? "Select" : "Sélectionner";
      const datecont = $('<div class="chxmags"><div class="ccont"><div class="calen"></div><div class="calfooter">' + txtselect + '</div></div></div>');
      const langdate = lang ? "en-US" : "fr-FR";

      const picker = new Litepicker({
        element: $('.skidates')[0],
        singleMode: false,
        numberOfColumns: 2,
        numberOfMonths: 2,
        moveByOneMonth: true,
        moduleRanges: true,
        lang: langdate,
        format: "DD MMM YYYY",
        minDate: startday,
        maxDate: dayend,
        delimiter: "  >  ",
        maxDays: Number(data.jourmax),
        tooltipText: lang ? { "one": "day", "other": "days" } : { "one": "jour", "other": "jours" },
        plugins: ['mobilefriendly'],
        autoRefresh: true,
        setup: (picker) => {
          picker.on('before:show', (ui) => {
            $('body').css({ "overflow": "hidden" }).append(datecont);
          });
          picker.on('hide', (ui) => {
            datecont.detach();
            $('body').css({ "overflow": "auto" });
          });
        },
        parentEl: datecont.find('.calen')[0],
      })

      picker.gotoDate(startday);
      picker.on('preselect', (date1, date2) => {
        const txtdate2 = date2 === undefined ? '' : date1.format('DD/MMM/YYYY') == date2.format('DD/MMM/YYYY') ? '' : ' au ' + date2.format('D MMM YYYY', langdate);
        $('.skidates')[0].innerHTML = date1.format('DD MMM YYYY', langdate) + txtdate2
        if (date2 === undefined) {
          $($skilocation.find(".dates input[name=dated]")).val(date1.format('DD/MM/YYYY'));
          $('.calfooter').text(txtselectend);
        } else {
          $('.calfooter').text(txtselect);

          const debut = dayjs(date1.format('YYYY/MM/DD'));
          const fin = dayjs(date2.format('YYYY/MM/DD'));
          $(".dates input[name=jour]").val(fin.diff(debut, "day") + 1);

        }
      });
      var inputmags = $skilocation.find("input[name=chxmagasin]");

      const zoom2 = (e) => {
        var zoomer = e.currentTarget;
        var offsetX, offsetY;
        offsetX = e.offsetX;
        offsetY = e.offsetY;
        const x = offsetX / zoomer.offsetWidth * 100;
        const y = offsetY / zoomer.offsetHeight * 100;
        zoomer.style.backgroundPosition = x + '% ' + y + '%';
      }


      inputmags.off().on("click", () => {
        $('body').css({ "overflow": "hidden" });
        // const eleRect = inputmags[0].getBoundingClientRect();
        const slchxmags = $('<div class="chxmags"><div class="chxmagscont"><div class="magtop"><div class="slmags"></div><div class="slplan"><div class="contplan"><figure class="zoom"></figure></div></div></div><div class="calfooter">' + textselectmag + '</div></div></div>');
        // slchxmags.css({ "top": eleRect.top + eleRect.height + window.scrollY + "px", "left": eleRect.left + "px" });
        var imagmag = $('<img class="media" title="plan" src="' + data.plan + '">');

        const closemag = () => {
          slchxmags.detach();
          document.removeEventListener('click', checkclick);
          $('body').css({ "overflow": "auto" });
        }

        preload([data.plan]).done(() => {
          slchxmags.find('.zoom').css({ "background-image": "url(" + data.plan + ")" });
          slchxmags.find('.zoom').append(imagmag);
          slchxmags.find('.zoom').css("opacity", 1);
          slchxmags.find('.zoom')[0].addEventListener('mousemove', zoom2, false);
          zoom();
        });
        data.magasins.map((el, index) => {
          if (el != " ") {
            const mag = $('<div class="slmag"><h3>' + el + '</h3><span class="slcoordo">' + data.adresse[index] + '</span><div class="magselect" data-id="' + index + '">' + magselect + '</div></div>');
            slchxmags.find('.slmags').append(mag);
          }
        });

        slchxmags.find('.magselect').off().on('click', e => {
          const idmag = $(e.currentTarget).attr("data-id");
          inputmags.val(data.magasins[idmag]);
          $("input[name=m]").val(idmag);
          closemag();
        })

        const checkclick = (event) => {
          const isClickInsideElement = slchxmags.find('.chxmagscont')[0].contains(event.target);
          if (!isClickInsideElement) {
            closemag();
          }
        }


        $('body').append(slchxmags).ready(function () {
          document.addEventListener('click', checkclick);
        });


      });
    },
    "json"
  );

  $skilocation
    .find(".louer")
    .off()
    .on("click", () => {
      const code = $skilocation.find("input[name=code]").val();
      $.get(url, { code: code }, code => {
        $skilocation.find("input[name=c]").val(code);
        $skilocation.submit();
      });
    });
};

export default datepicker;
