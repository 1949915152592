import preload from "./preload";
import supportsWebP from "supports-webp";
import debounce from "./debounce";
import cookie from "./cookie";

function cached(url) {
  var test = document.createElement("img");
  test.src = url;
  return test.complete || test.width + test.height > 0;
}

$.fn.lazyimg = function (callback) {
  let windowWidth = $(window).width();

  const loadimg = (el) => {
    const $this = $(el);
    const checkimg = $this.is("img");
    let prechargeurl = $this.attr("data-src");
    let prechargeurldark = $this.attr("data-srcdark");

    if (
      cookie.get("theme") &&
      cookie.get("theme") == "dark" &&
      $("body").attr("data-dark") != "" &&
      prechargeurldark
    ) {
      prechargeurl = prechargeurldark;
    } else if (
      window.matchMedia &&
      window.matchMedia("(prefers-color-scheme: dark)").matches &&
      $("body").attr("data-dark") != "" &&
      !cookie.get("theme") &&
      prechargeurldark
    ) {
      prechargeurl = prechargeurldark;
    }

    const bckgsize = $this.css("background-sze");

    if (prechargeurl) {
      if ($(window).width() > 500) {
        if ($this.attr("data-srcbg")) {
          prechargeurl = supportsWebP
            ? $this.attr("data-srcbgwp")
            : $this.attr("data-srcbg");
        }
      } else {
        if ($this.attr("data-srcsm")) {
          prechargeurl = supportsWebP
            ? $this.attr("data-srcsmwp")
            : $this.attr("data-srcsm");
        }
      }

      if (cached(prechargeurl)) {
        if (checkimg) {
          $(this)
            .css({
              "background-image": "",
              opacity: 1,
            })
            .attr("src", bckgsize);
        } else {
          if ($(this).hasClass("background")) {
            $(this)
              .removeClass("preload")
              .css({
                "background-image": "url(" + prechargeurl + ")",
                "background-size": bckgsize,
                opacity: 1,
                transition: 'none'
              });
            setTimeout(() => {
              $(this).css({ transition: 'all 1.5s' }).addClass('on');
            }, 200);
          } else {
            $(this)
              .removeClass("preload")
              .css({
                "background-image": "url(" + prechargeurl + ")",
                "background-size": bckgsize,
                opacity: 1,
              });
          }
        }
        if (callback) {
          callback();
        }
      } else {
        if ($this.attr("data-load")) {
          $this.addClass("preload").css({
            "background-image": "url(/icons/precharge.svg)",
            "background-size": "auto !important",
            opacity: 1,
          });
        }
        preload([prechargeurl]).done(() => {
          if (checkimg) {
            $(this)
              .css({
                "background-image": "",
                opacity: 1,
              })
              .attr("src", prechargeurl);

            if ($(this).hassClass("background")) {
              $(this).addClass('on');
            }
          } else {
            $(this).removeClass("preload").css({
              "background-image": "",
              opacity: 0,
              "background-size": bckgsize,
            });
            setTimeout(() => {
              if ($(this).hasClass("background")) {
                $(this).css({
                  "background-image": "url(" + prechargeurl + ")",
                  opacity: 1,
                  transition: 'none'
                });
                setTimeout(() => {
                  $(this).css({ transition: 'all 1.5s' }).addClass('on');
                }, 200);
              } else {
                $(this).css({
                  "background-image": "url(" + prechargeurl + ")",
                  opacity: 1,
                });
              }

            }, 300);
          }

          if (callback) {
            callback();
          }
        });
      }
    }
  };

  return this.each((i, el) => {
    loadimg(el);

    $(window).resize(
      debounce(() => {
        const curWidth = $(window).width();
        if (curWidth !== windowWidth) {
          windowWidth = curWidth;
          loadimg(el);
        }
      }, 200)
    );
  });
};
