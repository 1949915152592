$.fn.googlemaplarge = function (options) {
  const $main = $(this);
  const mapData = JSON.parse($main.attr("data-content"));
  const zoom = $main.attr("data-zoom");
  const buttonColor = $main.attr("data-button");
  const markerColor = $main.attr("data-markerColor");
  const key = $main.attr("data-key");
  const $map = $main.find(".map");
  const $mapList = $main.find(".markerinfo");
  const headTopHeight = $(".headtop").outerHeight();
  const headBottomHeight = $(".headbottom").outerHeight();

  let isMobile = false;

  if ($(window).width() < 801) {
    $main.addClass("mobile");
    isMobile = true;
  }

  const headerHeight = headTopHeight + headBottomHeight + (isMobile ? 80 : 120);

  // set $main height to 100vh
  $map.css("height", "calc( 100vh - " + headerHeight + "px )");
  $main.css("maxWidth", "100%");

  const svgIcon =
    '<svg width="62.863" height="49.437" version="1.1" xmlns="http://www.w3.org/2000/svg" ><defs><filter id="svg_3_blur" x="-.01132" y="-.018337" width="1.0226" height="1.0367"><feGaussianBlur stdDeviation="7.7"/></filter></defs><g transform="translate(-2319.4 -1808)"><g clip-rule="evenodd" fill-rule="evenodd"><path d="m2334.6 1856.9c-1.1198-5.4976-3.0944-10.073-5.4861-14.313-1.7739-3.1452-3.829-6.0482-5.7304-9.0983-0.6348-1.0181-1.1826-2.0938-1.7925-3.1504-1.2196-2.1129-2.2084-4.5626-2.1456-7.7402 0.061-3.1049 0.9594-5.5954 2.2542-7.6317 2.1297-3.3493 5.6969-6.0953 10.483-6.8169 3.9135-0.59 7.5827 0.4068 10.185 1.9283 2.126 1.2433 3.7727 2.9041 5.0242 4.8614 1.3063 2.0429 2.206 4.4564 2.2813 7.6045 0.038 1.6128-0.2253 3.1065-0.5974 4.3454-0.3764 1.254-0.9819 2.3023-1.5207 3.422-1.0519 2.1857-2.3703 4.1884-3.6937 6.1922-3.9416 5.9687-7.641 12.056-9.2613 20.396z" fill="currentcolor" stroke-miterlimit="10" stroke-width="1.0689"/><path transform="matrix(.028889 0 0 .028889 2313.5 1803.8)" d="m731.38 1839c131.6-120.87 211.31-235.98 269.14-350.49 42.89-84.95 70.54-169.17 106.95-254.06 12.16-28.34 28.51-56.57 42.52-85.3 28.02-57.45 72.66-118.14 169.03-178.1 94.16-58.59 193.95-93.756 291.58-114.7 160.56-34.442 344.28-37.299 502.59 15.593 129.45 43.244 204.68 113.46 233.78 178.86 23.78 53.44 21.43 108.18-1.0401 163.1-23.46 57.32-69.57 116.07-161.11 177.39-46.9 31.42-98.9 56.34-146.41 74.87-48.09 18.76-96.6 30.39-145.33 44.32-95.12 27.19-192.42 47.15-289.89 67.08-290.32 59.34-577.25 124.32-871.81 261.43z" filter="url(#svg_3_blur)" opacity=".25"/><circle cx="2334.6" cy="1822.6" r="5.2963" fill="#ffffff" stroke-width=".028889"/></g></g></svg>';

  $.getScript(
    "https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=marker&key=" +
      key,
    () => {
      var styles = [
        {
          stylers: [{ hue: "#587591" }],
        },
        {
          featureType: "road",
          elementType: "geometry",
          stylers: [{ lightness: 100 }, { visibility: "simplified" }],
        },
        {
          featureType: "road",
          elementType: "labels",
        },
        {
          featureType: "poi",
          stylers: [{ visibility: "off" }],
        },
      ];

      var styledMap = new google.maps.StyledMapType(styles, {
        name: "Styled Map",
      });
      var bounds = new google.maps.LatLngBounds();
      var map = new google.maps.Map($map[0], {
        center: new google.maps.LatLng(46.4430444, 4.0849144),
        mapId: "DEMO_MAP_ID",
        mapTypeControlOptions: {
          mapTypeIds: [google.maps.MapTypeId.ROADMAP, "map_style"],
        },
        zoom: Number(zoom),
        scaleControl: false,
        scrollwheel: false,
        // draggable: false,
        // disableDoubleClickZoom: true,
        panControl: false,
        mapTypeControl: false,
        mapTypeId: "roadmap",
      });

      map.mapTypes.set("map_style", styledMap);
      map.setMapTypeId("map_style");

      function setMapInfo(index) {
        if (isMobile) {
          $map.css("height", "calc( 100vh - " + (headerHeight + 220) + "px )");
        }
        $mapList.css("display", "block");
        const { nom, adresse, cp, ville, tel, lien } = mapData[index];
        const content = `<h4>${nom}</h4><p>${adresse}<br />${cp} ${ville}</p><p>Tel. ${tel}</p><a href="${lien}" class="esp" data-bouton="${buttonColor}" title="Voir le magasin">Voir le magasin</a>`;
        $mapList.html(content);
        $mapList.find(".esp").each(function () {
          window.bouttonAnim(this);
        });
      }

      function centrevu() {
        var markers = [];
        var icons = [];
        mapData.forEach((mark, index) => {
          var posibase = mark.position.split(",");
          var position = new google.maps.LatLng(posibase[0], posibase[1]);
          bounds.extend(position);

          const markerContent = {
            position: position,
            map: map,
          };

          icons[index] = new DOMParser();
          icons[index] = icons[index].parseFromString(
            svgIcon,
            "image/svg+xml"
          ).documentElement;
          icons[index].style.transition = ".3s all";
          icons[index].style.color = markerColor;
          markerContent.content = icons[index];

          markers[index] = new google.maps.marker.AdvancedMarkerElement(
            markerContent
          );

          markers[index].addListener("click", function () {
            map.panTo(markers[index].position);
            icons.forEach((icon) => {
              icon.style.transform = "scale(1)";
              icon.parentNode.parentNode.style.zIndex = 1;
            });
            setMapInfo(index);
            if (icons[index]) {
              // get parent of the icon
              icons[index].parentNode.parentNode.style.zIndex = 2;
              icons[index].style.transform = "scale(1.5)";
            }
          });
        });

        var listener = google.maps.event.addListener(map, "idle", function () {
          if (zoom && map.getZoom() > zoom) map.setZoom(Number(zoom));
          google.maps.event.removeListener(listener);
        });

        var text = $(this).find(".text").val();
        $main.find(".gm-style-iw").next("div").hide();

        if ($main.find(".lieu").length === 1) {
          map.setCenter(markers[0].position);
        } else {
          map.fitBounds(bounds);
        }
      }

      var globalvue =
        '<div class="globalvue"><i class="icon-zoom-out"></i></div>';
      $map.append(globalvue);

      $main.find(".globalvue").on("click", function () {
        map.fitBounds(bounds);
      });

      centrevu();
    }
  );
};
